import { Button, Card, Col, notification, Radio, Row, Tabs } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import APIService from '../../shared/api'
import { FundDetailDropdownContext, MMPFirmType } from '../../shared/api/context/FundDetailDropdownContext'
import { ExFirmDetails, MultiManagerPlatformType } from '../../shared/api/models/FirmDetails'
import { Colors } from '../../shared/colors'
import FundOfFirmTable, { EXFUND_FIRM_TABLE } from '../../shared/components/FundOfFirmTable'
import { TabContentWrapper } from '../../shared/components/TabContentWrapper'
import { history } from '../../shared/history'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { LeftNavExFirmEnum } from '../../shared/SearchExFirmLabel'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../slice/appSettingsSlice'
import { fetchUser } from '../../slice/userSlice'
import { allExColumns, ColumnPropsExtended, defaultColumns } from './ExColumnBuilder/columnSource'
import ExDashboardPerformance from './ExDashboardPerformance'
import { ExDraftTab } from './ExDraftTab'
import ExFundTableHeader from './ExFundTableHeader'
import ExpandedRowRender from './ExpandedRowRender'
import { ExRecentlyEditedTab } from './ExRecentlyEditedTab'
import { ModalCodeOfConduct } from './ModalCodeOfConduct'
import { ModalMultiManagerPlatform } from './ModalMultiManagerPlatform'
import { ModalDiversityInclusion } from './ModalDiversityInclusion'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  .ant-card-head-title {
    white-space: normal;
  }

  .ant-card-head {
    border-bottom: none;
    font-size: 1rem;
    padding: 0 1rem;
  }
  .ant-card-body {
    padding: 0 1rem 1rem 1rem;
    flex: 1;
  }
`
const StyledMyFundTab = styled(Tabs)`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    flex: 1;
  }
`

const FundFirmName = styled('span')`
  color: ${Colors.primary};
  cursor: pointer;
`

const ScrollBar = styled(PerfectScrollbar)`
  .ps__rail-x {
    z-index: 2;
  }
`

const StopCOCDate = new Date('07/01/2024')

type Props = {
  setShouldUpdate: (value: boolean) => void
  shouldUpdate: boolean
  setUpdatedFundPerformance: (updatedFund?: string) => void
}

const StyledButton = styled(Button)`
  display: flex;
  height: 22px;
  align-items: center;
`

const ExDashboardTab: React.FC<Props> = ({ setShouldUpdate, shouldUpdate, setUpdatedFundPerformance }) => {
  const { dataFirms, refetchFirm, fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const [showSidebar, setShowSidebar] = React.useState(true)
  const [activeTab, setActiveTab] = React.useState('Recently Edited')

  // get all multiManagerPlatformRes
  const [multiManagerPlatformResponses, setMultiManagerPlatformResponses] = React.useState<MMPFirmType[]>([])

  const [selectedFirm, setSelectedFirm] = React.useState<ExFirmDetails>()
  const [selectedMMPFirm, setSelectedMMPFirm] = React.useState<MMPFirmType>()

  // code of conduct
  const [showCOCModal, setShowCOCModal] = React.useState(false)
  // diversity and inclusion
  const [showDAIModal, setShowDAIModal] = React.useState(false)
  // multi manager platform
  const [showMMPModal, setShowMMPModal] = React.useState(false)

  const [notResponsedCOCFirms, setNotResponsedCOCFirms] = React.useState<ExFirmDetails[] | undefined>()
  const [notSetDAIFirms, setNotSetDAIFirms] = React.useState<ExFirmDetails[] | undefined>()
  const [notCompletedMMPFirms, setNotCompletedMMPFirms] = React.useState<MMPFirmType[] | undefined>()

  const { data: dataExFunds, invoke: getExFund, loading } = useServiceState(APIService.fundService.fetchExFunds)

  async function fetchMultiManagerPlatform(firm: ExFirmDetails) {
    try {
      const response = await APIService.firmDetailService.fetchMultiManagerPlatform(firm.firm_id)
      return {
        id: firm.firm_id,
        name: firm.firm_name,
        funds: response.data.funds,
      }
    } catch (error) {
      throw error
    }
  }

  React.useEffect(() => {
    getExFund()
  }, [getExFund])

  React.useEffect(() => {
    if (shouldUpdate) {
      getExFund()
      setShouldUpdate && setShouldUpdate(false)
    }
  }, [getExFund, setShouldUpdate, shouldUpdate])

  React.useEffect(() => {
    if (!dataFirms) {
      return
    }
    //
    // stop COC after 30/06/2024
    if (new Date() < StopCOCDate) {
      setNotResponsedCOCFirms(dataFirms?.filter((firm) => firm.agreed_to_code_of_conduct === null))
    } else {
      setNotResponsedCOCFirms([])
    }
    setNotSetDAIFirms(dataFirms?.filter((firm) => typeof firm.is_minority_or_woman_owned_firm !== 'boolean'))
    // get all multiManagerPlatformRes
    setMultiManagerPlatformResponses([])
    Promise.all(dataFirms.map((firm) => fetchMultiManagerPlatform(firm)))
      .then((responses) => {
        setMultiManagerPlatformResponses(responses)
      })
      .catch((error) => {
        notification.error({ message: error?.message || error })
      })
  }, [dataFirms])

  // show coc modal when there are still notResponsedCOCFirms, when coc modal close, it will filter out the one in the modal
  React.useEffect(() => {
    if (!notResponsedCOCFirms || notResponsedCOCFirms.length === 0 || showCOCModal) {
      return
    }
    if (notResponsedCOCFirms?.length > 0) {
      setSelectedFirm(notResponsedCOCFirms[0])
      setShowCOCModal(true)
    }
  }, [notResponsedCOCFirms, showCOCModal])
  // show mmp modal when there are still notCompletedMMPFirms, when mmp modal close, it will filter out the one in the modal
  React.useEffect(() => {
    // there are still COC Firms
    if (showCOCModal || (notResponsedCOCFirms && notResponsedCOCFirms.length > 0)) {
      return
    }
    if (!notSetDAIFirms || notSetDAIFirms.length === 0 || showDAIModal) {
      return
    }
    if (notSetDAIFirms?.length > 0) {
      setSelectedFirm(notSetDAIFirms[0])
      setShowDAIModal(true)
    }
  }, [notResponsedCOCFirms, showCOCModal, notSetDAIFirms, showDAIModal])
  // show mmp modal when there are still notCompletedMMPFirms, when mmp modal close, it will filter out the one in the modal
  React.useEffect(() => {
    // there are still COC Firms
    if (showCOCModal || (notResponsedCOCFirms && notResponsedCOCFirms.length > 0)) {
      return
    }
    // there is still DAI Modal
    if (showDAIModal || (notSetDAIFirms && notSetDAIFirms.length > 0)) {
      return
    }
    if (!notCompletedMMPFirms || notCompletedMMPFirms.length === 0 || showMMPModal) {
      return
    }
    if (notCompletedMMPFirms?.length > 0) {
      setSelectedMMPFirm(notCompletedMMPFirms[0])
      setShowMMPModal(true)
    }
  }, [notResponsedCOCFirms, showCOCModal, notSetDAIFirms, showDAIModal, notCompletedMMPFirms, showMMPModal])
  // find firm with incomplete mmp
  React.useEffect(() => {
    if (!multiManagerPlatformResponses || !multiManagerPlatformResponses.length) {
      return
    }

    const results = multiManagerPlatformResponses.reduce((prev, cur) => {
      const hasNull = cur.funds.some((fund) => fund.multi_manager_platform === null)
      if (hasNull) {
        return [...prev, cur]
      }
      return prev
      // return [...prev, cur]
    }, [] as { name: string; id: string; funds: MultiManagerPlatformType[] }[])
    setNotCompletedMMPFirms(results)
  }, [multiManagerPlatformResponses])
  //
  const handleCancelCOC = () => {
    if (!selectedFirm) {
      return
    }
    // remove cancelled Firm from notResponsedCOCFirms list then trigger check open next modal
    setNotResponsedCOCFirms(notResponsedCOCFirms?.filter((item) => item.firm_id !== selectedFirm.firm_id))
    setShowCOCModal(false)
  }

  const handleCancelDAI = () => {
    if (!selectedFirm) {
      return
    }
    // remove cancelled Firm from notResponsedCOCFirms list then trigger check open next modal
    setNotSetDAIFirms(notSetDAIFirms?.filter((item) => item.firm_id !== selectedFirm.firm_id))
    setShowDAIModal(false)
  }

  const handleCancelMMP = () => {
    if (!selectedMMPFirm) {
      return
    }
    //
    setNotCompletedMMPFirms((prev) => prev?.filter((item) => item.id !== selectedMMPFirm.id))
    setShowMMPModal(false)
  }

  const dispatch = useDispatch()

  const { exFundTableColumns } = useSelector((state: RootState) => state.appSettings)
  const { exFundSearchParams } = useSelector((state: RootState) => state.searchParams)

  const flattenAllColumns = allExColumns.flatMap((item) => item.columns)

  const exFundColumns =
    exFundTableColumns.length > 0
      ? exFundTableColumns.reduce((acc, key) => {
          const foundColumn = flattenAllColumns.find((item) => item.key === key)
          if (foundColumn) {
            return [...acc, foundColumn]
          }
          return acc
        }, [] as ColumnPropsExtended[])
      : // map((key: any) => flattenAllColumns.find((item) => item.key === key))
        defaultColumns

  const columns: ColumnProps<ExFirmDetails>[] = [
    {
      title: 'ID',
      dataIndex: 'firm_id',
      key: 'firm_id',
      width: 120,
      sorter: (a, b) => {
        return a.firm_id.localeCompare(b.firm_id)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'firm_name',
      key: 'firm_name',
      width: 300,
      sorter: (a, b) => {
        return a.firm_name.localeCompare(b.firm_name)
      },
      sortDirections: ['descend', 'ascend'],
      render: (text: string) => <FundFirmName>{text}</FundFirmName>,
      onCell: (record: ExFirmDetails) => ({
        onClick: () => {
          dispatch(
            addTabs({
              id: `${PREFIX_EXTERNAL}-${record.firm_id}`,
              name: record.firm_name,
              type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
            }),
          )
        },
      }),
    },
    {
      title: 'Latest Firm Asset',
      dataIndex: 'latest_firm_asset',
      key: 'latest_firm_asset',
      width: 200,
      render: (_, record) => (
        <>
          <StyledButton
            type="link"
            style={{ padding: '4px 0px' }}
            onClick={() => {
              dispatch(
                addTabs({
                  id: `${PREFIX_EXTERNAL}-${record.firm_id}`,
                  name: record.firm_name,
                  type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                  activeNav: LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT,
                }),
              )
            }}
          >
            {record.latest_firm_asset?.toFixed(3)}
          </StyledButton>
        </>
      ),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Latest Firm Asset Date',
      dataIndex: 'latest_firm_asset_date',
      key: 'latest_firm_asset_date',
      width: 200,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Latest Firm Hedge Fund Asset',
      dataIndex: 'latest_firm_asset_hedge_fund',
      key: 'latest_firm_asset_hedge_fund',
      width: 200,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => (
        <>
          <StyledButton
            type="link"
            style={{ padding: '4px 0px' }}
            onClick={() => {
              dispatch(
                addTabs({
                  id: `${PREFIX_EXTERNAL}-${record.firm_id}`,
                  name: record.firm_name,
                  type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                  activeNav: LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT,
                }),
              )
            }}
          >
            {record.latest_firm_asset_hedge_fund?.toFixed(3)}
          </StyledButton>
        </>
      ),
    },
    {
      title: 'Latest Firm Hedge Fund Asset Date',
      dataIndex: 'latest_firm_asset_hedge_fund_date',
      key: 'latest_firm_asset_hedge_fund_date',
      width: 200,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'HFR Submitter Code Of Conduct',
      dataIndex: 'agreed_to_code_of_conduct',
      key: 'agreed_to_code_of_conduct',
      width: 200,
      render: (_, record) => (
        <span
          style={{
            color: record.agreed_to_code_of_conduct ? Colors.success : Colors.danger,
            display: 'flex',
          }}
        >
          <b
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {record.agreed_to_code_of_conduct === true
              ? 'AGREED'
              : record.agreed_to_code_of_conduct === null
              ? 'NOT RESPONSED'
              : 'NOT AGREED'}
          </b>
          {!record.agreed_to_code_of_conduct && (
            <StyledButton
              type="link"
              style={{ marginLeft: 8 }}
              onClick={() => {
                setSelectedFirm(record)
                setShowCOCModal(true)
              }}
            >
              Change Status
            </StyledButton>
          )}
        </span>
      ),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Firm Contact Info',
      key: 'action',
      render: (_, record) => (
        <>
          <StyledButton
            type="link"
            style={{ padding: '4px 0' }}
            onClick={() => {
              dispatch(
                addTabs({
                  id: `${PREFIX_EXTERNAL}-${record.firm_id}`,
                  name: record.firm_name,
                  type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                  activeNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
                }),
              )
            }}
          >
            Update
          </StyledButton>
        </>
      ),
    },
  ]

  const refreshUser = () => {
    dispatch(fetchUser())
    refetchFirm()
  }

  return (
    <TabContentWrapper style={{ padding: '1rem' }}>
      <Row gutter={14} style={{ height: '100%' }}>
        {showSidebar && (
          <Col span={6} style={{ height: '100%' }}>
            <PerfectScrollbar>
              <StyledCard
                size="small"
                title={
                  <span>
                    {`Multi-Manager "Pod" Platform `}
                    <span style={{ color: '#ff4d4f', fontWeight: 700 }}>(Please complete if you have not)</span>
                  </span>
                }
                style={{ marginTop: 8, borderColor: Colors.primary }}
              >
                {dataFirms &&
                  dataFirms.map((item) => {
                    return (
                      <div
                        style={{ cursor: 'pointer', color: Colors.primary }}
                        key={item.firm_id}
                        onClick={() => history.push(`/firms/${item.firm_id}/firm-multi-manager`)}
                      >
                        {item.firm_name}
                      </div>
                    )
                  })}
              </StyledCard>
              <StyledCard
                size="small"
                title={`Firm Diversity & Inclusion`}
                style={{ marginTop: 8, borderColor: Colors.primary }}
              >
                {dataFirms &&
                  dataFirms.map((item) => {
                    return (
                      <div
                        style={{ cursor: 'pointer', color: Colors.primary }}
                        key={item.firm_id}
                        onClick={() => history.push(`/firms/${item.firm_id}/firm-diversity-inclusion`)}
                      >
                        {item.firm_name}
                      </div>
                    )
                  })}
              </StyledCard>
              <StyledCard size="small" title="ESG Survey" style={{ marginTop: 8, borderColor: Colors.primary }}>
                {dataFirms &&
                  dataFirms.map((item) => {
                    return (
                      <div
                        style={{ cursor: 'pointer', color: Colors.primary }}
                        key={item.firm_id}
                        onClick={() => history.push(`/firms/${item.firm_id}/esg`)}
                      >
                        {item.firm_name}
                      </div>
                    )
                  })}
              </StyledCard>
              <StyledCard
                size="small"
                title="HFR Index Licensing Quick Quote"
                style={{ marginTop: 8, borderColor: Colors.primary }}
              >
                <div
                  style={{ cursor: 'pointer', color: Colors.primary }}
                  onClick={() =>
                    window.open(
                      'https://go.hfr.com/l/1020832/2024-01-02/74j5g?source=ManagerLink&relatedsalesperson=indexdata@hfr.com',
                    )
                  }
                >
                  Special HFR Index Licensing Rates for Managers - Click for a Quote
                </div>
              </StyledCard>
              <StyledCard
                size="small"
                title={
                  <span>
                    <span style={{ color: '#ff4d4f', fontWeight: 700 }}>NEW</span>
                    {`: Broadcast to HFR Clients`}
                  </span>
                }
                style={{ marginTop: 8, borderColor: Colors.primary }}
              >
                <div
                  style={{ cursor: 'pointer', color: Colors.primary }}
                  onClick={() => window.open('https://www.hfr.com/hfr-media-mgr-form/')}
                >
                  Catalyze your fund’s growth with HFR Media
                </div>
              </StyledCard>
              <StyledMyFundTab
                hideAdd
                onChange={(key) => {
                  setActiveTab(key)
                }}
                activeKey={activeTab}
              >
                <Tabs.TabPane tab="Recently Edited" closable={false} key="Recently Edited">
                  <ExRecentlyEditedTab />
                </Tabs.TabPane>
                <Tabs.TabPane tab="In Progress" closable={false} key="Draft">
                  <ExDraftTab />
                </Tabs.TabPane>
              </StyledMyFundTab>
            </PerfectScrollbar>
          </Col>
        )}
        <Col span={showSidebar ? 18 : 24} style={{ height: '100%' }}>
          <ScrollBar>
            <StyledCard title="All Firms" size="small" style={{ marginBottom: '1rem', border: 'none' }}>
              <Table
                style={{ width: '100%' }}
                rowKey="firm_id"
                size="small"
                loading={!dataFirms}
                dataSource={dataFirms}
                expandable={{
                  expandedRowRender: (record) => (
                    <ExpandedRowRender
                      record={record}
                      refreshUser={refreshUser}
                      setShowModel={setShowCOCModal}
                      setSelectedFirm={setSelectedFirm}
                    />
                  ),
                  expandIcon: () => {
                    return null
                  },
                  expandedRowKeys: dataFirms
                    ?.filter((firm) => firm.agreed_to_code_of_conduct === null)
                    .map((firm) => firm.firm_id),
                }}
                defaultExpandAllRows={true}
                columns={columns}
                pagination={dataFirms && dataFirms.length > 10 ? undefined : false}
              />
              {selectedFirm && showCOCModal && (
                <ModalCodeOfConduct
                  firmId={selectedFirm.firm_id}
                  firmName={selectedFirm.firm_name}
                  showModal={showCOCModal}
                  setShowModal={setShowCOCModal}
                  onCancel={handleCancelCOC}
                  refreshUser={refreshUser}
                />
              )}
              {selectedFirm && showDAIModal && (
                <ModalDiversityInclusion
                  showModal={showDAIModal}
                  onCancel={handleCancelDAI}
                  fundDataDropdown={fundDataDropdown}
                  selectedFirm={selectedFirm}
                />
              )}
              {selectedMMPFirm && showMMPModal && (
                <ModalMultiManagerPlatform
                  firmId={selectedMMPFirm.id}
                  firmName={selectedMMPFirm.name}
                  showModal={showMMPModal}
                  onCancel={handleCancelMMP}
                />
              )}
            </StyledCard>
            <StyledCard
              size="small"
              title={
                <ExFundTableHeader
                  onShowSideBar={() => {
                    setShowSidebar(!showSidebar)
                  }}
                  showSidebar={showSidebar}
                />
              }
            >
              <FundOfFirmTable
                type={EXFUND_FIRM_TABLE.EXFUND}
                dataSource={dataExFunds}
                loadingTable={loading}
                reloadTable={() => {
                  getExFund()
                }}
                initialColumn={exFundColumns}
                initialFilter={exFundSearchParams}
              />
            </StyledCard>
            {dataExFunds && (
              <ExDashboardPerformance
                shouldUpdate={shouldUpdate}
                setShouldUpdate={setShouldUpdate}
                setUpdatedFundPerformance={setUpdatedFundPerformance}
                dataExFunds={dataExFunds}
              />
            )}
          </ScrollBar>
        </Col>
      </Row>
    </TabContentWrapper>
  )
}

export default ExDashboardTab
