import React from 'react'
import {
  BulbOutlined,
  KeyOutlined,
  LinkedinOutlined,
  LogoutOutlined,
  TwitterOutlined,
  UserOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Layout, Menu, Modal, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import logo from '../../assets/img/hfr-logo.png'
import { logout } from '../../slice/authSlice'
import { toggleShowPrompt } from '../../slice/appSettingsSlice'
import APIService from '../api'
import { Colors } from '../colors'
import { useServiceState } from '../hooks/useServiceState'
import { ContactUsModal } from './ContactUsModal'
import { UserProfileDetails } from '../api/models/ResponsesTypes'

const { Header, Content, Footer } = Layout

const StyledHeader = styled(Header)`
  padding: 0;
  display: flex;
  justify-content: space-between;
`
const StyledFooter = styled(Footer)`
  background: #001529;
  height: 64px;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  color: ${Colors.white};
`

const StyledContent = styled(Content)`
  padding: 16px 24px;
  flex: 1;
  display: flex;
`

const LogoContainer = styled(Link)`
  float: left;
`

const ImgLogo = styled.img`
  margin: 6px 10px;
  height: 36px;
  width: auto;
`

type Props = {}

export interface StateProps {
  user: UserProfileDetails
  showPrompt: boolean
}

interface DispatchProps {
  toggleShowPrompt: (showPrompt: boolean) => void
}

const MainLayout: React.FC<Props> = ({ children }) => {
  const history = useHistory()
  const location = useRouteMatch()
  const { isLoading } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()

  const [showModal, setShowModal] = React.useState(false)
  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState(
    APIService.commonService.fetchDisclaimerPrompt,
  )

  const { invoke: approveDisclaimerPrompt, loading } = useServiceState(APIService.commonService.approveDisclaimerPrompt)
  const [dontShowAgain, setDontShowAgain] = React.useState(false)

  const { user } = useSelector((state: RootState) => state.user)
  const { showPrompt } = useSelector((state: RootState) => state.appSettings)

  React.useEffect(() => {
    invokeDisclaimerData()
  }, [invokeDisclaimerData])

  const handleSubmitPrompt = () => {
    if (dontShowAgain) {
      approveDisclaimerPrompt()
    }
    dispatch(toggleShowPrompt(false))
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <StyledHeader>
        <LogoContainer to={'/'}>
          <ImgLogo alt={'Logo'} src={logo} className={'logo'} />
        </LogoContainer>
        <Menu mode="horizontal" theme="dark" defaultSelectedKeys={['/']} selectedKeys={[location.path]}>
          <Menu.Item key="/" onClick={() => history.push('/')}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="/manager-list" onClick={() => history.push('/manager-list')}>
            Manager List
          </Menu.Item>
          <Menu.Item key="/analysis" onClick={() => history.push('/analysis')}>
            Analysis
          </Menu.Item>
          <Menu.Item key="/help-and-faqs" onClick={() => history.push('/help-and-faqs')}>
            <BulbOutlined />
            <span>Help And FAQs</span>
          </Menu.Item>
          <Menu.SubMenu key="/system" title="System">
            <Menu.Item key="/system/code-table" onClick={() => history.push('/system/code-table')}>
              Code Table
            </Menu.Item>
            <Menu.Item>
              <a
                href={`${process.env.REACT_APP_AUTH_API_URL}/29caceb3-30a1-492e-a47f-72f2bcf3c01b/trakker/codelist/`}
                target="code_list"
              >
                Code List
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                href={`${process.env.REACT_APP_AUTH_API_URL}/29caceb3-30a1-492e-a47f-72f2bcf3c01b/trakker/`}
                target="strategy"
              >
                Strategy &amp; Sub-Strategy
              </a>
            </Menu.Item>

            <Menu.Item key="/system/benchmarks" onClick={() => history.push('/system/benchmarks')}>
              Benchmark
            </Menu.Item>
            <Menu.Item key="/system/activity-log" onClick={() => history.push('/system/activity-log')}>
              Activity Log
            </Menu.Item>
            <Menu.Item key="/system/index-manager" onClick={() => history.push('/system/index-manager')}>
              Index Manager
            </Menu.Item>
            <Menu.Item key="/system/universe-manager" onClick={() => history.push('/system/universe-manager')}>
              Universe Manager
            </Menu.Item>
            <Menu.Item key="/system/search-query" onClick={() => history.push('/system/search-query')}>
              Search Query Manager
            </Menu.Item>
            <Menu.SubMenu key="/system/index-performance-update" title="Index Performance Update">
              <Menu.Item key="asset">
                <a
                  href={`${process.env.REACT_APP_AUTH_API_URL}/29caceb3-30a1-492e-a47f-72f2bcf3c01b/trakker/asset/`}
                  target="asset"
                >
                  Asset
                </a>
              </Menu.Item>
              <Menu.Item key="nav">
                <a
                  href={`${process.env.REACT_APP_AUTH_API_URL}/29caceb3-30a1-492e-a47f-72f2bcf3c01b/trakker/nav/`}
                  target="nav"
                >
                  NAV
                </a>
              </Menu.Item>
              <Menu.Item key="ror">
                <a
                  href={`${process.env.REACT_APP_AUTH_API_URL}/29caceb3-30a1-492e-a47f-72f2bcf3c01b/trakker/ror/`}
                  target="ror"
                >
                  ROR
                </a>
              </Menu.Item>
              <Menu.Item key="hfrx-constituents">
                <a
                  href={`${process.env.REACT_APP_AUTH_API_URL}/29caceb3-30a1-492e-a47f-72f2bcf3c01b/trakker/exporthfrxconstituents/`}
                  target="hfrx-constituents"
                >
                  HFRX Constituents
                </a>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="/user"
            title={
              isLoading ? (
                <Spin />
              ) : (
                <>
                  <UserOutlined /> {user?.first_name} {user?.last_name}
                </>
              )
            }
          >
            {/* <Menu.Item onClick={() => history.push('/user/profile')}>
              <UserOutlined />
              Your Profile
            </Menu.Item> */}

            <Menu.Item>
              <Link to={'/admin'} target="_blank">
                <UserOutlined />
                Open Admin Panel
              </Link>
            </Menu.Item>

            <Menu.Item onClick={() => history.push('/user/change-password')}>
              <KeyOutlined />
              Change Password
            </Menu.Item>
            <Menu.Item onClick={() => dispatch(logout())}>
              <LogoutOutlined />
              Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
      <StyledFooter>
        <h4 style={{ display: 'flex', alignItems: 'center', margin: 0, color: 'white' }}>© 2025 HFR</h4>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
            Contact Us
          </div>
          <a
            href="https://www.hfr.com/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: Colors.white, marginLeft: '2rem' }}
          >
            Privacy Policy
          </a>
          <a
            href="https://twitter.com/hfrinc"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: Colors.white, marginLeft: '2rem' }}
          >
            <TwitterOutlined />
          </a>
          <a
            href="https://www.linkedin.com/company/hfrinc"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: Colors.white, marginLeft: '2rem' }}
          >
            <LinkedinOutlined />
          </a>
          <a
            href="https://www.youtube.com/channel/UCTCjgM5GNFj-6L-ZOvGOJmg"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: Colors.white, marginLeft: '2rem' }}
          >
            <YoutubeOutlined />
          </a>
        </div>
      </StyledFooter>
      <ContactUsModal showModal={showModal} onRequestClose={() => setShowModal(false)} />
      {disclaimerData && (
        <Modal
          width="60%"
          title={disclaimerData?.prompt ? disclaimerData?.prompt.title : ''}
          visible={showPrompt && disclaimerData?.show_prompt}
          onCancel={() => dispatch(toggleShowPrompt(false))}
          footer={
            <>
              <Checkbox checked={dontShowAgain} onChange={(e) => setDontShowAgain(e.target.checked)}>
                Don&apos;t show this again
              </Checkbox>
              <Button loading={loading} type="primary" onClick={handleSubmitPrompt}>
                OK
              </Button>
            </>
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: disclaimerData?.prompt ? `${disclaimerData?.prompt.content}` : '',
            }}
          />
        </Modal>
      )}
    </Layout>
  )
}

export default MainLayout
