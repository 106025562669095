import {
  BulbOutlined,
  KeyOutlined,
  LinkedinOutlined,
  LogoutOutlined,
  TwitterOutlined,
  UserOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'
import { Layout, Menu, Spin } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import logo from '../../assets/img/hfr-logo.png'
import { logout } from '../../slice/authSlice'
import { Colors } from '../colors'
import { ContactUsModal } from './ContactUsModal'

const { Content, Footer } = Layout

const StyledContent = styled(Content)`
  padding: 16px 24px;
  flex: 1;
  display: flex;
`
const StyledFooter = styled(Footer)`
  background: #001529;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  color: ${Colors.white};
`
const ImgLogo = styled.img`
  margin: 6px 10px;
  height: 36px;
  width: auto;
`

type Props = {}

const ExternalMainLayout: React.FC<Props> = ({ children }) => {
  const history = useHistory()
  const location = useRouteMatch()
  const { isLoading } = useSelector((state: RootState) => state.auth)
  const { user } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const [showModal, setShowModal] = React.useState(false)

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Header style={{ display: 'flex', justifyContent: 'space-between', height: 48 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/" style={{ marginRight: 8 }}>
            <ImgLogo alt={'Logo'} src={logo} className={'logo'} />
          </Link>
          <a href="https://www.hfr.com" rel="noopener noreferrer" target="hfr.com">
            HFR.com
          </a>
        </div>
        <Menu
          mode="horizontal"
          theme="dark"
          defaultSelectedKeys={['/']}
          selectedKeys={[location.path]}
          style={{ lineHeight: '46px' }}
        >
          <Menu.Item
            key="https://www.hfr.com/hfr-media-mgr-form/"
            onClick={() => window.open('https://www.hfr.com/hfr-media-mgr-form/')}
            style={{ lineHeight: '48px' }}
          >
            <span style={{ textDecoration: 'underline' }}>New</span>: Broadcast to HFR Clients
          </Menu.Item>
          <Menu.Item
            key="https://go.hfr.com/l/1020832/2024-01-02/74j5g?source=ManagerLink&relatedsalesperson=indexdata@hfr.com"
            onClick={() =>
              window.open(
                'https://go.hfr.com/l/1020832/2024-01-02/74j5g?source=ManagerLink&relatedsalesperson=indexdata@hfr.com',
              )
            }
            style={{ lineHeight: '48px' }}
          >
            License HFR Indices
          </Menu.Item>
          <Menu.Item key="/" onClick={() => history.push('/')} style={{ lineHeight: '48px' }}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="/help-and-faqs" onClick={() => history.push('/help-and-faqs')} style={{ lineHeight: '48px' }}>
            <BulbOutlined />
            <span>Help And FAQs</span>
          </Menu.Item>
          <Menu.SubMenu
            key="/user"
            style={{ lineHeight: '48px' }}
            title={
              isLoading ? (
                <Spin />
              ) : (
                <>
                  <UserOutlined /> {user?.first_name} {user?.last_name}
                </>
              )
            }
          >
            <Menu.Item onClick={() => history.push('/user/change-password')}>
              <KeyOutlined />
              Change Password
            </Menu.Item>
            <Menu.Item onClick={() => dispatch(logout())}>
              <LogoutOutlined />
              Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Layout.Header>
      <StyledContent>{children}</StyledContent>
      <StyledFooter>
        <h4 style={{ display: 'flex', alignItems: 'center', margin: 0, color: 'white' }}>© 2025 HFR</h4>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
            Contact Us
          </div>
          <a
            href="https://www.hfr.com/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: Colors.white, marginLeft: '2rem' }}
          >
            Privacy Policy
          </a>
          <a
            href="https://twitter.com/hfrinc"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: Colors.white, marginLeft: '2rem' }}
          >
            <TwitterOutlined />
          </a>
          <a
            href="https://www.linkedin.com/company/hfrinc"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: Colors.white, marginLeft: '2rem' }}
          >
            <LinkedinOutlined />
          </a>
          <a
            href="https://www.youtube.com/channel/UCTCjgM5GNFj-6L-ZOvGOJmg"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: Colors.white, marginLeft: '2rem' }}
          >
            <YoutubeOutlined />
          </a>
        </div>
      </StyledFooter>
      <ContactUsModal showModal={showModal} onRequestClose={() => setShowModal(false)} />
    </Layout>
  )
}

export default ExternalMainLayout
